import { RouteRecordRaw, RouteLocationNormalized, RouteLocationNormalizedLoaded, NavigationGuardNext } from 'vue-router'
import { useUserStore } from 'src/stores/user'
import { useMainStore } from 'src/stores/main'
import * as api from 'src/api'
import { supabase } from 'src/supabase'

async function switchTenant(tenantId: string) {
  const user = useUserStore()
  const mainStore = useMainStore()
  console.log('switching tenant')
  user.isSwitchingTenants = true
  const { error } = await api.tenants.switch(tenantId)
  if (!error) {
    console.log('tenant switched!, refreshing session')
    user.activeTenantId = tenantId
    const { data, error: refreshError } = await supabase.auth.refreshSession()
    if (!refreshError && data) {
      console.log('session refreshed!, refreshing app data')
      await mainStore.refreshAppData()
      await user.setFromSession(data.session)
    }
  }
  console.log('switching tenant done')
  user.isSwitchingTenants = false
}

function userLoggedIn(to: RouteLocationNormalized, from: RouteLocationNormalizedLoaded, next: NavigationGuardNext) {
  updateDocumentTitle(to)
  const user = useUserStore()
  const mainStore = useMainStore()

  if (user.session && user.profile) {
    // User session and profile are already available
    // Check if the user is accessing a tenant-specific route
    console.log('checking for tenantCode', to.params.tenantCode)
    if (to.params.tenantCode) switchTenantIfNeeded(to, from, next)
    else next()
  } else {
    console.log('waiting for session data...')
    // Wait for user session and profile data to become available
    const timeout = 5000 // 5 seconds
    let remaining = timeout

    const interval = setInterval(async () => {
      remaining -= 100
      if (user.session && user.profile && mainStore.isLoaded) {
        clearInterval(interval)
        if (to.params.tenantCode) await switchTenantIfNeeded(to, from, next)
        else next()
      } else if (remaining <= 0) {
        clearInterval(interval)
        next({ name: 'login' })
      }
    }, 100)
  }
}

async function switchTenantIfNeeded(to: RouteLocationNormalized, from: RouteLocationNormalizedLoaded, next: NavigationGuardNext) {
  console.log('beforeEnter switchTenantIfNeeded')
  const { tenantCode } = to.params
  const user = useUserStore()
  const mainStore = useMainStore()
  console.log('tenantCode is ', tenantCode)
  console.log('mainStore.tenants is ', mainStore.tenants)
  const tenantId = mainStore.tenants.find((tenant) => tenant.code === tenantCode)?.id
  console.log('tenantId is ', tenantId)
  console.log('user.activeTenantId is ', user.activeTenantId)
  if (!tenantId) next({ name: 'error', params: { message: 'Tenant not found' } })
  else if (user.activeTenantId === tenantId) next()
  else {
    await switchTenant(tenantId)
    next()
  }
}

function updateDocumentTitle(to: RouteLocationNormalized) {
  const appName = 'Levur' // Your app's default name
  const pageTitle = to.meta.title || 'Default Page Title'
  document.title = `${pageTitle} | ${appName}`
}

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('layouts/EmptyLayout.vue'),
    children: [
      { path: '', name: 'login', component: () => import('src/pages/auth/LoginPage.vue'), meta: { auth: false, title: 'Login' } },
      { path: 'password-reset/', name: 'forgot-password', component: () => import('src/pages/auth/ForgotPasswordPage.vue'), meta: { auth: false, title: 'Forgot Password' } },
      { path: 'password-reset/reset', name: 'reset-password', component: () => import('src/pages/auth/ResetPasswordPage.vue'), meta: { auth: false, title: 'Reset Password' } }
    ]
  },
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    beforeEnter: userLoggedIn,
    children: [
      // ---------------------
      // Personal
      // ---------------------
      { path: 'dashboard', name: 'dashboard', component: () => import('src/pages/personal/DashboardPage.vue'), meta: { auth: true, title: 'Dashboard' } },
      { path: 'timesheet', name: 'timesheet', component: () => import('src/pages/personal/TimesheetPage.vue'), meta: { auth: true, title: 'Timesheet' } },
      { path: 'employee-expenses', name: 'employee-expenses', component: () => import('src/pages/personal/EmployeeExpensesPage.vue'), meta: { auth: true, title: 'Employee Expenses' } },
      { path: 'todos', name: 'todos', component: () => import('src/pages/personal/TodosPage.vue'), meta: { auth: true, title: 'Todos' } },
      { path: 'notifications', name: 'notifications', component: () => import('src/pages/personal/NotificationsPage.vue'), meta: { auth: true, title: 'Notifications' } },
      // Email
      { path: 'email', name: 'email', component: () => import('src/pages/personal/EmailPage.vue'), meta: { auth: true, title: 'Email' } },
      { path: 'email/:id', name: 'email-single', component: () => import('src/pages/personal/EmailPage.vue'), meta: { auth: true, title: 'Email' } },
      { path: 'email/new', name: 'email-compose', component: () => import('src/pages/personal/EmailPage.vue'), meta: { auth: true, title: 'Email' } },
      // AI Tools
      { path: 'ai/summarize', name: 'ai-summarize', component: () => import('src/pages/ai/SummarizePage.vue'), meta: { auth: true, title: 'AI Tools | Summarize' } },
      { path: 'ai/chat', name: 'ai-chat', component: () => import('src/pages/ai/GPTChatPage.vue'), meta: { auth: true, title: 'AI Tools | GPT Chat' } },

      // ---------------------
      // Tenant Specific
      // ---------------------

      { path: ':tenantCode/company-overview', name: 'tenant-company-overview', component: () => import('src/pages/hq/CompanyOverviewPage.vue'), meta: { auth: true, title: 'Company Overview' } },
      // Team
      { path: ':tenantCode/team', name: 'tenant-team', component: () => import('src/pages/team/TeamListPage.vue'), meta: { auth: true, title: 'Team' } },
      { path: ':tenantCode/team/:id', name: 'tenant-team-member', component: () => import('src/pages/team/TeamMemberPage.vue'), meta: { auth: true, title: 'Team Member' } },
      // Shared Employees
      { path: ':tenantCode/shared-employees', name: 'tenant-shared-employees', component: () => import('src/pages/hq/SharedEmployeesPage.vue'), meta: { auth: true, title: 'Shared Employees' } },
      // Clients
      { path: ':tenantCode/clients', name: 'tenant-clients', component: () => import('src/pages/clients/ClientsListPage.vue'), meta: { auth: true, title: 'Clients' } },
      { path: ':tenantCode/clients/:id', name: 'tenant-client', component: () => import('src/pages/clients/ClientPage.vue'), meta: { auth: true, title: 'Client' } },
      // Contacts
      { path: ':tenantCode/contacts', name: 'tenant-contacts', component: () => import('src/pages/contacts/ContactsListPage.vue'), meta: { auth: true, title: 'Contacts' } },
      { path: ':tenantCode/contacts/:id', name: 'tenant-contact', component: () => import('src/pages/contacts/ContactPage.vue'), meta: { auth: true, title: 'Contact' } },
      // Vendors
      { path: ':tenantCode/vendors', name: 'tenant-vendors', component: () => import('src/pages/vendors/VendorsListPage.vue'), meta: { auth: true, title: 'Vendors' } },
      { path: ':tenantCode/vendors/:id', name: 'tenant-vendor', component: () => import('src/pages/vendors/VendorPage.vue'), meta: { auth: true, title: 'Vendor' } },
      // RFQs
      { path: 'rfqs/ofcc', name: 'rfqs-ofcc', component: () => import('src/pages/rfqs/OfccRfqsPage.vue'), meta: { auth: true, title: 'OFCC' } },
      // Opportunities
      { path: ':tenantCode/opportunities', name: 'tenant-opportunities', component: () => import('src/pages/opportunities/OpportunitiesListPage.vue'), meta: { auth: true, title: 'Opportunities' } },
      { path: ':tenantCode/opportunities/:id', name: 'tenant-opportunity', component: () => import('src/pages/opportunities/OppPage.vue'), meta: { auth: true, title: 'Opportunity' } },
      { path: ':tenantCode/add-service-requests', name: 'tenant-add-service-requests', component: () => import('src/pages/opportunities/AddServiceRequestsListPage.vue'), meta: { auth: true, title: 'Add Service Requests' } },
      { path: ':tenantCode/signature-requests', name: 'tenant-signature-requests', component: () => import('src/pages/SignatureRequestsPage.vue'), meta: { auth: true, title: 'Signature Requests' } },
      { path: ':tenantCode/opportunities/project-approval', name: 'tenant-project-approval', component: () => import('src/pages/opportunities/ProjectApprovalPage.vue') },
      // Projects
      { path: ':tenantCode/projects/activate', name: 'tenant-project-activation', component: () => import('src/pages/projects/ProjectActivationPage.vue'), meta: { auth: true, title: 'Project Activation' } },
      { path: ':tenantCode/projects', name: 'tenant-projects', component: () => import('src/pages/projects/ProjectsListPage.vue'), meta: { auth: true, title: 'Projects' } },
      { path: ':tenantCode/projects/:id', name: 'tenant-project', component: () => import('src/pages/projects/ProjectPage.vue'), meta: { auth: true, title: 'Project' } },
      { path: ':tenantCode/progress-estimates', name: 'tenant-progress-estimates', component: () => import('src/pages/admin/ProgressEstimatesPage.vue'), meta: { auth: true, title: 'Progress Estimates' } },
      { path: ':tenantCode/project-portfolio', name: 'tenant-project-portfolio', component: () => import('src/pages/project-portfolio/ProjectPortfolioPage.vue'), meta: { auth: true, title: 'Project Portfolio' } },
      { path: ':tenantCode/project-portfolio/add', name: 'tenant-project-portfolio-add', component: () => import('src/pages/project-portfolio/AddPortfolioProjectPage.vue'), meta: { auth: true, title: 'Add Portfolio Project' } },
      { path: ':tenantCode/project-portfolio/:id', name: 'tenant-portfolio-project', component: () => import('src/pages/project-portfolio/PortfolioProjectPage.vue'), meta: { auth: true, title: 'Portfolio Project' } },
      // Billing
      { path: ':tenantCode/billing/billing-overview', name: 'tenant-billing-overview', component: () => import('src/pages/billing/BillingOverviewPage.vue'), meta: { auth: true, title: 'Billing Overview' } },
      { path: ':tenantCode/billing/planned-billings', name: 'tenant-planned-billings', component: () => import('src/pages/billing/PlannedBillingsPage.vue'), meta: { auth: true, title: 'Planned Billings' } },
      { path: ':tenantCode/billing/billing-cycles', name: 'tenant-billing-cycles', component: () => import('src/pages/billing/BillingCyclesPage.vue'), meta: { auth: true, title: 'Billing Cycles' } },
      { path: ':tenantCode/billing/pre-billing', name: 'tenant-pre-billing', component: () => import('src/pages/billing/PreBillingPage.vue'), meta: { auth: true, title: 'Pre-Billing' } },
      { path: ':tenantCode/billing/invoicing', name: 'tenant-invoicing', component: () => import('src/pages/billing/InvoicingPage.vue'), meta: { auth: true, title: 'Invoicing' } },
      { path: ':tenantCode/billing/payments', name: 'tenant-payments', component: () => import('src/pages/billing/PaymentsPage.vue'), meta: { auth: true, title: 'Payments' } },
      { path: ':tenantCode/billing/invoices/:number', name: 'tenant-invoice', component: () => import('src/pages/billing/InvoicePage.vue'), meta: { auth: true, title: 'Invoice' } },
      { path: ':tenantCode/billing/time-management', name: 'tenant-time-management', component: () => import('src/pages/billing/TimeEntryManagementPage.vue'), meta: { auth: true, title: 'Time Entry Management' } },
      { path: ':tenantCode/billing/time-management', name: 'tenant-expense-management', component: () => import('src/pages/billing/ExpenseManagementPage.vue'), meta: { auth: true, title: 'Expense Management' } },
      // Finance
      { path: ':tenantCode/expense-processing', name: 'tenant-expense-processing', component: () => import('pages/expenses/ExpenseProcessingPage.vue'), meta: { auth: true, title: 'Expense Processing' } },
      // HR
      { path: ':tenantCode/hr/overview', name: 'tenant-hr-overview', component: () => import('pages/hr/HROverviewPage.vue'), meta: { auth: true, title: 'HR Overview' } },
      { path: ':tenantCode/hr/timesheet-review', name: 'tenant-timesheet-review', component: () => import('pages/hr/TimesheetReviewPage.vue'), meta: { auth: true, title: 'Timesheet Review' } },
      { path: ':tenantCode/hr/time-off-requests', name: 'tenant-time-off-requests', component: () => import('pages/hr/TimeOffRequestsPage.vue'), meta: { auth: true, title: 'Time Off Requests' } },
      { path: ':tenantCode/hr/mte-review', name: 'tenant-mte-review', component: () => import('pages/hr/MTEReviewPage.vue'), meta: { auth: true, title: 'MTE Review' } },
      { path: ':tenantCode/hr/time-off-ledger', name: 'tenant-time-off-ledger', component: () => import('pages/hr/TimeOffLedgerPage.vue'), meta: { auth: true, title: 'Time Off Ledger' } },
      { path: ':tenantCode/hr/pay-scales', name: 'tenant-pay-scales', component: () => import('pages/hr/PayScalesPage.vue'), meta: { auth: true, title: 'Pay Scales' } },
      { path: ':tenantCode/hr/new-years', name: 'tenant-new-years', component: () => import('pages/hr/NewYearsPage.vue'), meta: { auth: true, title: 'New Years Page' } },
      { path: ':tenantCode/hr/move-employee', name: 'tenant-move-employee', component: () => import('pages/hr/MoveEmployeePage.vue'), meta: { auth: true, title: 'Move Employee' } },
      // Admin pages
      { path: ':tenantCode/access-control', name: 'tenant-access-control', component: () => import('src/pages/admin/AccessControlPage.vue'), meta: { auth: true, title: 'Access Control' } },
      { path: ':tenantCode/preapprove-timesheets', name: 'tenant-preapprove-timesheets', component: () => import('src/pages/admin/PreApproveTimesheetsPage.vue'), meta: { auth: true, title: 'Pre-Approve Timesheets' } },
      { path: ':tenantCode/cross-tenant-employees', name: 'tenant-cross-tenant-employees', component: () => import('src/pages/admin/CrossTenantEmployeesPage.vue'), meta: { auth: true, title: 'Cross Tenant Employees' } },
      // Reports
      { path: ':tenantCode/project-time-report', name: 'tenant-project-time-report', component: () => import('src/pages/reports/ProjectTimeReportPage.vue'), meta: { auth: true, title: 'Project Time Report' } },
      { path: ':tenantCode/department-time-report', name: 'tenant-department-time-report', component: () => import('src/pages/reports/TriumphTimeReportPage.vue'), meta: { auth: true, title: 'Triumph Report' } },
      // Utilities
      { path: ':tenantCode/utils/merge-tasks', name: 'tenant-merge-tasks', component: () => import('src/pages/utils/MergeTasksPage.vue'), meta: { auth: true, title: 'Merge Tasks' } },
      // Settings
      { path: ':tenantCode/settings/:section?', name: 'tenant-settings', component: () => import('src/pages/SettingsPage.vue'), meta: { auth: true, title: 'Settings' } },

      // ---------------------
      // Company
      // ---------------------
      { path: 'company-overview', name: 'company-overview', component: () => import('src/pages/hq/CompanyOverviewPage.vue'), meta: { auth: true, title: 'Company Overview' } },
      // Team
      { path: 'team', name: 'team', component: () => import('src/pages/team/TeamListPage.vue'), meta: { auth: true, title: 'Team' } },
      { path: 'team/:id', name: 'team-member', component: () => import('src/pages/team/TeamMemberPage.vue'), meta: { auth: true, title: 'Team Member' } },
      // Shared Employees
      { path: 'shared-employees', name: 'shared-employees', component: () => import('src/pages/hq/SharedEmployeesPage.vue'), meta: { auth: true, title: 'Shared Employees' } },
      // Clients
      { path: 'clients', name: 'clients', component: () => import('src/pages/clients/ClientsListPage.vue'), meta: { auth: true, title: 'Clients' } },
      { path: 'clients/:id', name: 'client', component: () => import('src/pages/clients/ClientPage.vue'), meta: { auth: true, title: 'Client' } },
      // Contacts
      { path: 'contacts', name: 'contacts', component: () => import('src/pages/contacts/ContactsListPage.vue'), meta: { auth: true, title: 'Contacts' } },
      { path: 'contacts/:id', name: 'contact', component: () => import('src/pages/contacts/ContactPage.vue'), meta: { auth: true, title: 'Contact' } },
      // Vendors
      { path: 'vendors', name: 'vendors', component: () => import('src/pages/vendors/VendorsListPage.vue'), meta: { auth: true, title: 'Vendors' } },
      { path: 'vendors/:id', name: 'vendor', component: () => import('src/pages/vendors/VendorPage.vue'), meta: { auth: true, title: 'Vendor' } },
      // RFQs
      { path: 'rfqs/ofcc', name: 'rfqs-ofcc', component: () => import('src/pages/rfqs/OfccRfqsPage.vue'), meta: { auth: true, title: 'OFCC' } },
      // Opportunities
      { path: 'bid-requests', name: 'bid-requests', component: () => import('src/pages/bid-requests/BidRequestsListPage.vue'), meta: { auth: true, title: 'Bid Requests' } },
      { path: 'bid-requests/:id', name: 'bid-request', component: () => import('src/pages/bid-requests/BidRequestPage.vue'), meta: { auth: true, title: 'Bid Request' } },
      { path: 'opportunities', name: 'opportunities', component: () => import('src/pages/opportunities/OpportunitiesListPage.vue'), meta: { auth: true, title: 'Opportunities' } },
      { path: 'opportunities/:id', name: 'opportunity', component: () => import('src/pages/opportunities/OppPage.vue'), meta: { auth: true, title: 'Opportunity' } },
      { path: 'add-service-requests', name: 'add-service-requests', component: () => import('src/pages/opportunities/AddServiceRequestsListPage.vue'), meta: { auth: true, title: 'Add Service Requests' } },
      { path: 'signature-requests', name: 'signature-requests', component: () => import('src/pages/SignatureRequestsPage.vue'), meta: { auth: true, title: 'Signature Requests' } },
      { path: 'opportunities/project-approval', name: 'project-approval', component: () => import('src/pages/opportunities/ProjectApprovalPage.vue') },
      // Projects
      { path: 'projects/activate', name: 'project-activation', component: () => import('src/pages/projects/ProjectActivationPage.vue'), meta: { auth: true, title: 'Project Activation' } },
      { path: 'projects', name: 'projects', component: () => import('src/pages/projects/ProjectsListPage.vue'), meta: { auth: true, title: 'Projects' } },
      { path: 'projects/:id', name: 'project', component: () => import('src/pages/projects/ProjectPage.vue'), meta: { auth: true, title: 'Project' } },
      { path: 'progress-estimates', name: 'progress-estimates', component: () => import('src/pages/admin/ProgressEstimatesPage.vue'), meta: { auth: true, title: 'Progress Estimates' } },
      // Project Portfolio
      { path: 'project-portfolio', name: 'project-portfolio', component: () => import('src/pages/project-portfolio/ProjectPortfolioPage.vue'), meta: { auth: true, title: 'Project Portfolio' } },
      { path: 'project-portfolio/add', name: 'project-portfolio-add', component: () => import('src/pages/project-portfolio/AddPortfolioProjectPage.vue'), meta: { auth: true, title: 'Add Portfolio Project' } },
      { path: 'project-portfolio/add-photos', name: 'project-portfolio-add-photos', component: () => import('src/pages/project-portfolio/AddPortfolioProjectPhotosPage.vue'), meta: { auth: true, title: 'Add Photos to Portfolio Project' } },
      { path: 'project-portfolio/:id', name: 'portfolio-project', component: () => import('src/pages/project-portfolio/PortfolioProjectPage.vue'), meta: { auth: true, title: 'Portfolio Project' } },
      { path: 'project-portfolio/firm/:id', name: 'portfolio-project-firm', component: () => import('src/pages/project-portfolio/PortfolioProjectFirmPage.vue'), meta: { auth: true, title: 'Portfolio Project Firm' } },
      { path: 'project-portfolio/person/:id', name: 'portfolio-project-person', component: () => import('src/pages/project-portfolio/PortfolioProjectPersonPage.vue'), meta: { auth: true, title: 'Portfolio Project Person' } },
      { path: 'project-portfolio/find-similar', name: 'portfolio-project-search', component: () => import('src/pages/project-portfolio/FindSimilarProjectsPage.vue'), meta: { auth: true, title: 'Find Similar Projects' } },
      // Billing
      { path: 'billing/billing-overview', name: 'billing-overview', component: () => import('src/pages/billing/BillingOverviewPage.vue'), meta: { auth: true, title: 'Billing Overview' } },
      { path: 'billing/planned-billings', name: 'planned-billings', component: () => import('src/pages/billing/PlannedBillingsPage.vue'), meta: { auth: true, title: 'Planned Billings' } },
      { path: 'billing/billing-cycles', name: 'billing-cycles', component: () => import('src/pages/billing/BillingCyclesPage.vue'), meta: { auth: true, title: 'Billing Cycles' } },
      { path: 'billing/pre-billing', name: 'pre-billing', component: () => import('src/pages/billing/PreBillingPage.vue'), meta: { auth: true, title: 'Pre-Billing' } },
      { path: 'billing/invoicing', name: 'invoicing', component: () => import('src/pages/billing/InvoicingPage.vue'), meta: { auth: true, title: 'Invoicing' } },
      { path: 'billing/payments', name: 'payments', component: () => import('src/pages/billing/PaymentsPage.vue'), meta: { auth: true, title: 'Payments' } },
      { path: 'billing/invoices/:number', name: 'invoice', component: () => import('src/pages/billing/InvoicePage.vue'), meta: { auth: true, title: 'Invoice' } },
      { path: 'billing/time-management', name: 'time-management', component: () => import('src/pages/billing/TimeEntryManagementPage.vue'), meta: { auth: true, title: 'Time Entry Management' } },
      { path: 'billing/expense-management', name: 'expense-management', component: () => import('src/pages/billing/ExpenseManagementPage.vue'), meta: { auth: true, title: 'Expense Management' } },
      // Finance
      { path: 'expense-processing', name: 'expense-processing', component: () => import('pages/expenses/ExpenseProcessingPage.vue'), meta: { auth: true, title: 'Expense Processing' } },
      // HR
      { path: 'hr/overview', name: 'hr-overview', component: () => import('pages/hr/HROverviewPage.vue'), meta: { auth: true, title: 'HR Overview' } },
      { path: 'hr/timesheet-review', name: 'timesheet-review', component: () => import('pages/hr/TimesheetReviewPage.vue'), meta: { auth: true, title: 'Timesheet Review' } },
      { path: 'hr/time-off-requests', name: 'time-off-requests', component: () => import('pages/hr/TimeOffRequestsPage.vue'), meta: { auth: true, title: 'Time Off Requests' } },
      { path: 'hr/mte-review', name: 'mte-review', component: () => import('pages/hr/MTEReviewPage.vue'), meta: { auth: true, title: 'MTE Review' } },
      { path: 'hr/manage-employees', name: 'manage-employees', component: () => import('pages/hr/ManageEmployeesPage.vue'), meta: { auth: true, title: 'Manage Employees' } },
      { path: 'hr/time-off-ledger', name: 'time-off-ledger', component: () => import('pages/hr/TimeOffLedgerPage.vue'), meta: { auth: true, title: 'Time Off Ledger' } },
      { path: 'hr/pay-scales', name: 'pay-scales', component: () => import('pages/hr/PayScalesPage.vue'), meta: { auth: true, title: 'Pay Scales' } },
      { path: 'hr/new-years', name: 'new-years', component: () => import('pages/hr/NewYearsPage.vue'), meta: { auth: true, title: 'New Years' } },
      { path: 'hr/move-employee', name: 'move-employee', component: () => import('pages/hr/MoveEmployeePage.vue'), meta: { auth: true, title: 'Move Employee' } },
      // Admin pages
      { path: 'access-control', name: 'access-control', component: () => import('src/pages/admin/AccessControlPage.vue'), meta: { auth: true, title: 'Access Control' } },
      { path: 'preapprove-timesheets', name: 'preapprove-timesheets', component: () => import('src/pages/admin/PreApproveTimesheetsPage.vue'), meta: { auth: true, title: 'Pre-Approve Timesheets' } },
      { path: 'cross-tenant-employees', name: 'cross-tenant-employees', component: () => import('src/pages/admin/CrossTenantEmployeesPage.vue'), meta: { auth: true, title: 'Cross Tenant Employees' } },
      // Reports
      { path: 'project-time-report', name: 'project-time-report', component: () => import('src/pages/reports/ProjectTimeReportPage.vue'), meta: { auth: true, title: 'Project Time Report' } },
      { path: 'department-time-report', name: 'department-time-report', component: () => import('src/pages/reports/TriumphTimeReportPage.vue'), meta: { auth: true, title: 'Triumph Report' } },
      // Utilities
      { path: 'utils/merge-tasks', name: 'merge-tasks', component: () => import('src/pages/utils/MergeTasksPage.vue'), meta: { auth: true, title: 'Merge Tasks' } },
      // Settings
      { path: 'settings/:section?', name: 'settings', component: () => import('src/pages/SettingsPage.vue'), meta: { auth: true, title: 'Settings' } },
      // Profile
      { path: 'profile', name: 'profile', component: () => import('src/pages/ProfilePage.vue'), meta: { auth: true, title: 'User Profile' } },
      // Logout
      { path: 'logout', name: 'logout', component: () => import('src/pages/auth/LogoutPage.vue'), meta: { auth: true, title: 'Logout' } },

      // ---------------------
      // HIDDEN
      // ---------------------

      // ---------------------
      // Testing
      // ---------------------
      { path: 'testing/test', name: 'test', component: () => import('src/pages/testing/TestPage.vue'), meta: { auth: true, title: 'Test | Testing' } },
      // { path: 'testing/stripe', name: 'test-stripe', component: () => import('src/pages/testing/StripeTestPage.vue'), meta: { auth: true, title: 'Stripe | Testing' } },
      // { path: 'testing/test-proposal-doc', name: 'tes-proposal-doc', component: () => import('src/pages/testing/ProposalDocTestPage.vue'), meta: { auth: true, title: 'Proposal Doc | Testing' } },
      // { path: 'testing/test-email-message-list', name: 'test-email-message-list', component: () => import('src/pages/testing/EmailMessageListTestPage.vue'), meta: { auth: true, title: 'EmailMessageInput | Testing' } },
      // { path: 'testing/test-uppy', name: 'test-uppy', component: () => import('src/pages/testing/UppyTestPage.vue'), meta: { auth: true, title: 'Uppy Test | Testing' } },
      // { path: 'testing/test-modals', name: 'test-modals', component: () => import('src/pages/testing/ModalsTestPage.vue'), meta: { auth: true, title: 'Modal Tests | Testing' } },
      // { path: 'testing/test-selects', name: 'test-select', component: () => import('src/pages/testing/SelectsTestPage.vue'), meta: { auth: true, title: 'Select Tests | Testing' } },
      // { path: 'testing/test-dynamic-selects', name: 'test-dynamic-select', component: () => import('src/pages/testing/DynamicSelectTestPage.vue'), meta: { auth: true, title: 'Dynamic Selects | Testing' } },
      // { path: 'testing/test-input-date', name: 'test-input-date', component: () => import('src/pages/testing/InputDateTestPage.vue'), meta: { auth: true, title: 'Test InputDate | Testing' } },
      // { path: 'testing/quasar-components', name: 'quasar-components', component: () => import('src/pages/testing/QuasarComponents.vue'), meta: { auth: true, title: 'Quasar Components | Testing' } },
      // { path: 'testing/quasar-component-gallery', name: 'quasar-component-gallery', component: () => import('src/pages/testing/QuasarComponentGallery.vue'), meta: { auth: true, title: 'Quasar Component Gallery | Testing' } },
      // { path: 'testing/test-opp-estimate', name: 'test-opp-estimate', component: () => import('src/pages/testing/OppEstimateTestPage.vue'), meta: { auth: true, title: 'Opp Estimate | Testing' } },
      // { path: 'testing/test-opp-pipeline', name: 'test-opp-pipeline', component: () => import('src/pages/testing/OppPipelineTestPage.vue'), meta: { auth: true, title: 'Opp Pipeline | Testing' } },
      // { path: 'testing/test-labeled-input', name: 'test-labeled-input', component: () => import('src/pages/testing/LabeledInputTestPage.vue'), meta: { auth: true, title: 'Labeled Input | Testing' } },
      // { path: 'testing/test-inputs', name: 'test-inputs', component: () => import('src/pages/testing/InputsTestPage.vue'), meta: { auth: true, title: 'Inputs | Testing' } },
      // { path: 'testing/test-inputmoney', name: 'test-inputmoney', component: () => import('src/pages/testing/InputMoneyTestPage.vue'), meta: { auth: true, title: 'InputMoney | Testing' } },
      // { path: 'testing/test-openai', name: 'test-openai', component: () => import('src/pages/testing/OpenAITestPage.vue'), meta: { auth: true, title: 'OpenAI | Testing' } }
      // { path: 'testing/test-quill', name: 'test-quill', component: () => import('src/pages/testing/QuillTestPage.vue'), meta: { auth: true, title: 'Quill | Testing' } },
      // { path: 'testing/test-richedit', name: 'test-richedit', component: () => import('src/pages/testing/RichEditTestPage.vue'), meta: { auth: true, title: 'RichEdit | Testing' } },
      // { path: 'testing/test-email-message-input', name: 'test-email-message-input', component: () => import('src/pages/testing/EmailMessageInputTestPage.vue'), meta: { auth: true, title: 'EmailMessageInput | Testing' } },
      // { path: 'testing/test-basedialog', name: 'test-basedialog', component: () => import('src/pages/testing/BaseDialogTestPage.vue'), meta: { auth: true, title: 'BaseDialogTestPage | Testing' } },
      // { path: 'testing/test-entity-types', name: 'test-entity-types', component: () => import('src/pages/testing/EntityTypeTestPage.vue'), meta: { auth: true, title: 'Entity Types | Testing' } },
      // { path: 'testing/test-entity-types-2', name: 'test-entity-types-2', component: () => import('src/pages/testing/EntityTypeTestPage2.vue'), meta: { auth: true, title: 'Entity Types | Testing' } },
      // { path: 'testing/test-client-entity', name: 'test-client-entity', component: () => import('src/pages/testing/ClientEntityTestPage.vue'), meta: { auth: true, title: 'Client Entity | Testing' } },
      // { path: 'testing/test-entity-api', name: 'test-entity-api', component: () => import('src/pages/testing/EntityAPITestPage.vue'), meta: { auth: true, title: 'Entity API | Testing' } },
      { path: 'testing/test-charts', name: 'test-charts', component: () => import('src/pages/testing/ChartsTestPage.vue'), meta: { auth: true, title: 'Charts | Testing' } }
      // { path: 'testing/test-opp-estimate-controls', name: 'test-opp-estimate-controls', component: () => import('src/pages/testing/OppEstimateControlsTestPage.vue'), meta: { auth: true, title: 'Opp Estimate Controls | Testing' } },
      // { path: 'testing/test-search', name: 'test-search', component: () => import('src/pages/testing/SearchTestPage.vue'), meta: { auth: true, title: 'Search | Testing' } },
      // { path: 'testing/test-sign-doc', name: 'test-sign-doc', component: () => import('src/pages/testing/DocSignTestPage.vue'), meta: { auth: true, title: 'Sign Doc | Testing' } },
      // { path: 'testing/test-sign-doc-new', name: 'test-sign-doc-new', component: () => import('src/pages/testing/DocSignTestPage2.vue'), meta: { auth: true, title: 'Sign Doc | Testing' } },
      // { path: 'testing/test-send-email', name: 'test-send-email', component: () => import('src/pages/testing/SendEmailTestPage.vue'), meta: { auth: true, title: 'Send Email | Testing' } },
      // { path: 'testing/test-sort', name: 'test-sort', component: () => import('src/pages/testing/SortTestPage.vue'), meta: { auth: true, title: 'Sorting | Testing' } }
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue')
  }
]

export default routes
