import { ref, computed } from 'vue'
import type { Ref } from 'vue'
import { defineStore } from 'pinia'
import { Session } from '@supabase/gotrue-js/src/lib/types'
import { Employee, Profile, Tenant, UserSetting } from 'src/types'
import * as api from 'src/api'
import { supabase } from 'src/supabase'
import { useMainStore } from './main'

type SimpleRole = { name: string, role_slug: string, tenant_id: string }

export const useUserStore = defineStore('user', () => {
  // --------------------
  // Same for all tenants
  // --------------------
  const session: Ref<Session | null> = ref(null)
  const id: Ref<string | null> = ref(null)
  const email: Ref<string | null> = ref(null)
  const firstName: Ref<string | null> = ref(null)
  const lastName: Ref<string | null> = ref(null)
  const name: Ref<string | null> = ref(null)
  const employee: Ref<Employee | null> = ref(null)
  const profile: Ref<Profile | null> = ref(null)
  const allRoles: Ref<SimpleRole[]> = ref([]) // contains roles for all tenants
  const permissions: Ref<{ action: string, resource: string }[]> = ref([])

  function can(action: string, resource: string) {
    return permissions.value.some((p) => p.action === action && p.resource === resource)
  }

  const tenantId = computed(() => {
    if (!session.value) return null
    return session.value.user.app_metadata.tenantId || null
  })

  const initials = computed<string>(() => {
    if (firstName.value && lastName.value) return firstName.value.charAt(0) + lastName.value.charAt(0)
    else if (firstName.value) return firstName.value.charAt(0)
    else return ''
  })

  const emailDomain = computed(() => email.value?.split('@')[1] || null)

  // The tenant ids the user has access to
  const tenants = computed<string[]>(() => {
    return allRoles.value.map((role) => role.tenant_id).filter((value, index, self) => self.indexOf(value) === index)
  })

  const activeTenant = computed<Tenant | null>(() => {
    const mainStore = useMainStore()
    return mainStore.tenants.find((tenant: Tenant) => tenant.id === activeTenantId.value) || null
  })

  const isSSOuser = computed(() => {
    return session.value?.user.app_metadata.provider?.startsWith('sso')
  })

  // --------------------
  // Tenant-specific
  // --------------------
  const isSwitchingTenants = ref(false)
  const activeTenantId: Ref<string | null> = ref(null)
  const settings: Ref<UserSetting[]> = ref([])

  const isHome = computed(() => {
    return tenantId.value === activeTenantId.value
  })

  const roles = computed<SimpleRole[]>(() => {
    return allRoles.value.filter((role) => role.tenant_id === activeTenantId.value)
  })

  const isAdmin = computed(() => {
    return roles.value.some((role) => (role.role_slug === 'admin' || role.role_slug === 'super-user') && role.tenant_id === activeTenantId.value)
  })

  /** Initializes the user store from a session */
  async function setFromSession(newSession: Session | null) {
    if (!newSession) clear()
    // else if (!newSession.user.app_metadata.tenantId) supabase.auth.refreshSession()
    else {
      const oldAccessToken = session.value?.access_token
      id.value = newSession.user.id || null
      email.value = newSession.user.email || null
      session.value = newSession
      if (id.value) {
        type UserLoginData = {
          profile: Profile
          employee: Employee
          roles: SimpleRole[]
          permissions: { action: string, resource: string }[]
        }
        const { data, error } = await supabase.rpc('get_user_login_data')
        const userData = data as unknown as UserLoginData
        if (!error && data) {
          firstName.value = userData.profile.first_name || ''
          lastName.value = userData.profile.last_name || ''
          name.value = userData.profile.name || ''
          employee.value = userData.employee
          profile.value = userData.profile
          // profile.value.roles = userData.roles
          allRoles.value = userData.roles
          activeTenantId.value = session.value.user.app_metadata.activeTenantId || session.value.user.app_metadata.tenantId
          permissions.value = userData.permissions
        }
        refreshSettings()
        if (newSession.access_token !== oldAccessToken) {
          const mainStore = useMainStore()
          await mainStore.refreshAppData()
        }
      }
    }
  }

  function refreshSettings() {
    api.userSettings.getAll({ select: 'key, type, bool_value, number_value, string_value' }).then(({ data, error }) => {
      if (!error && data) {
        settings.value = data
      }
    })
  }

  function getSetting(settingKey: string): boolean | number | string | undefined | null {
    const setting = settings.value.find((s) => s.key === settingKey)
    if (!setting) return undefined
    if (setting.type === 'boolean') return setting.bool_value
    if (setting.type === 'number') return setting.number_value
    if (setting.type === 'string') return setting.string_value
    return undefined
  }

  function setProfile(newProfile: Profile) {
    profile.value = newProfile
    firstName.value = newProfile.first_name || ''
    lastName.value = newProfile.last_name || ''
    name.value = newProfile.name || ''
  }

  function clear() {
    session.value = null
    id.value = null
    email.value = null
    firstName.value = null
    lastName.value = null
    allRoles.value = []
  }

  return {
    // Same for all tenants
    session,
    id,
    email,
    firstName,
    lastName,
    name,
    initials,

    employee,
    profile,
    emailDomain,
    tenantId,
    tenants,
    allRoles,
    isSSOuser,

    // Tenant-specific
    isSwitchingTenants,
    activeTenantId,
    isHome,
    isAdmin,
    settings,
    roles,
    permissions,
    activeTenant,

    // Functions
    setFromSession,
    clear,
    setProfile,
    refreshSettings,
    can,
    getSetting
  }
})
