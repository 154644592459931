import { PostgrestFilterBuilder } from '@supabase/postgrest-js'
import { supabase } from 'src/supabase'
import { Database } from 'src/schema'
import { GenericSchema } from '@supabase/supabase-js/dist/module/lib/types'
import { useUserStore } from 'src/stores/user'

const fullTextSearchColumn = 'fts'

/********************************/
/*            TYPES             */
/********************************/
// API Features
export enum EntityAPIFeatures {
  softDelete = 'softDelete',
  fts = 'fts',
}
// Return Types
export type DataResult<Entity> = { data: Entity[], error: undefined }
export type SingleResult<Entity> = { data: Entity, error: undefined }
export type CountResult = { count: number, error: undefined }
export type ErrorResult = { data: undefined, error: string }
export type CountErrorResult = { count: undefined, error: string }
export type SingleResponse<Entity> = SingleResult<Entity> | ErrorResult
export type DataResponse<Entity> = DataResult<Entity> | ErrorResult
export type CountResponse = CountResult | CountErrorResult
// Condition Types
export type Operator = 'eq' | 'neq' | 'gt' | 'lt' | 'gte' | 'lte' | 'like' | 'ilike' | 'is' | 'in' | 'contains' | 'containedBy' | 'rangeGt' | 'rangeGte' | 'rangeLt' | 'rangeLte' | 'rangeAdjacent' | 'overlaps'
export type Condition<T> = {
  [K in keyof T]?: {
    operator: Operator
    value: string | number | boolean | null | string[] | number[]
  }
}
// Sort Types
export type SortByForeign = { by: string, table: string }
export type SortBy = string | SortByForeign
// Filter Types
export type FilterOperation = 'in' | 'eq' | 'neq' | 'gt' | 'lt' | 'gte' | 'lte' | 'startsWith' | 'endsWith' | 'contains' | 'equals' | 'notEquals' | 'ne' | 'greaterThan' | 'lessThan' | 'greaterThanOrEqual' | 'lessThanOrEqual' | 'between' | 'notBetween' | 'isEmpty' | 'isNotEmpty' | 'isTrue' | 'isFalse' | 'like' | 'ilike'
export type Filter = {
  by: string // Column name
  op: FilterOperation // Operation
  value?: string | number | boolean | null | string[] | number[] | boolean[] | null[]
  value2?: string | number | boolean | null
  locked?: boolean
  hidden?: boolean
}
// Query Options
export type QueryOptions = {
  count?: boolean,
  select?: string,
  start?: number,
  end?: number,
  sortBy?: SortBy,
  sortDescending?: boolean,
  filters?: Filter[],
  limit?: number,
  ftsColumn?: string
}

/********************************/
/*        ABSTRACT CLASS        */
/********************************/
type APIOpts = {
  centralized?: boolean
  ftsColumn?: string
}
abstract class AbstractAPI {
  private _tableName: string
  private _selectString: string = '*'
  private _primaryKey: string
  private _centralized: boolean = false
  private _ftsColumn: string = fullTextSearchColumn

  get tableName(): string {
    return this._tableName
  }

  set tableName(value: string) {
    this._tableName = value
  }

  get selectString(): string {
    return this._selectString
  }

  set selectString(value: string) {
    this._selectString = value
  }

  get primaryKey(): string {
    return this._primaryKey
  }

  set primaryKey(value: string) {
    this._primaryKey = value
  }

  get centralized(): boolean {
    return this._centralized
  }

  set centralized(value: boolean) {
    this._centralized = value
  }

  get ftsColumn(): string {
    return this._ftsColumn
  }

  set ftsColumn(value: string) {
    this._ftsColumn = value
  }

  constructor(tableName: string, primaryKey: string, selectString = '*', apiOpts?: APIOpts) {
    this._tableName = tableName
    this._selectString = selectString
    this._primaryKey = primaryKey
    this._centralized = apiOpts?.centralized ?? false
    this._ftsColumn = apiOpts?.ftsColumn ?? fullTextSearchColumn
  }

  getQueryOptions(options?: QueryOptions): QueryOptions {
    const queryOptions = { ...options } ?? {}
    if (queryOptions.select === undefined) queryOptions.select = this.selectString
    return queryOptions
  }
}

/********************************/
/*          INTERFACES          */
/********************************/
// Entity
export interface IEntityAPI<Entity, EntityInput, PrimaryKeyValueType extends string | number> {
  count(options?: QueryOptions): Promise<CountResponse>
  get(id: PrimaryKeyValueType, selectString?: string): Promise<SingleResponse<Entity>>
  getAll(options?: QueryOptions): Promise<DataResponse<Entity>>
  add(itemToAdd: EntityInput): Promise<SingleResponse<Entity>>
  addMany(toAdd: EntityInput[]): Promise<DataResponse<Entity>>
  update(id: PrimaryKeyValueType, updates: Partial<EntityInput>): Promise<SingleResponse<Entity>>
  updateAll(updates: Partial<EntityInput>): Promise<DataResponse<Entity>>
  updateMany(toUpdate: Entity[] | PrimaryKeyValueType[], updates: Partial<EntityInput>): Promise<DataResponse<Entity>>
  updateByConditions(conditions: Condition<Entity>, updates: Partial<EntityInput>): Promise<DataResponse<Entity>>
  hardDelete(id: PrimaryKeyValueType): Promise<SingleResponse<Entity>>
  hardDeleteMany(toDelete: Entity[] | PrimaryKeyValueType[]): Promise<DataResponse<Entity>>
}

// Entity Feature (Soft Deletion)
export interface IEntityAPIWithSoftDelete<Entity, PrimaryKeyValueType extends string | number> {
  count(options?: QueryOptions): Promise<CountResponse>
  delete(id: PrimaryKeyValueType): Promise<SingleResponse<Entity>>
  deleteMany(toDelete: Entity[] | PrimaryKeyValueType[]): Promise<DataResponse<Entity>>
  restore(id: PrimaryKeyValueType): Promise<SingleResponse<Entity>>
  restoreMany(toRestore: Entity[] | PrimaryKeyValueType[]): Promise<DataResponse<Entity>>
}
// Entity Feature (Full Text Search)
export interface IEntityAPIWithFTS<Entity> {
  search(search: string, options?: QueryOptions): Promise<DataResponse<Entity>>
  count(search: string, options?: QueryOptions): Promise<CountResponse>
}

// Combined interface
export interface IEntityAPIWithAllFeatures<Entity, EntityInput, PrimaryKeyValueType extends string | number> extends AbstractAPI, IEntityAPI<Entity, EntityInput, PrimaryKeyValueType> {
  softDelete?: IEntityAPIWithSoftDelete<Entity, PrimaryKeyValueType>
  fts?: IEntityAPIWithFTS<Entity>
}

/********************************/
/*            CLASSES           */
/********************************/
/**
 * Entity Feature (Soft Deletion)
 */
class SoftDeletionAPI<Entity, PrimaryKeyValueType extends string | number, TableName extends keyof Database['public']['Tables']> extends AbstractAPI implements IEntityAPIWithSoftDelete<Entity, PrimaryKeyValueType> {
  async getAll(options?: QueryOptions): Promise<DataResponse<Entity>> {
    const queryOptions = this.getQueryOptions(options)
    if (!queryOptions.filters) queryOptions.filters = []
    queryOptions.filters.push({ by: 'is_deleted', op: 'eq', value: true })
    const query = buildQuery<Entity, TableName>(this.tableName, queryOptions)
    const { data, error } = await query
    if (error) return { error: error.message, data: undefined }
    else if (data) return { data: data as Entity[], error: undefined }
    else return { error: 'No data returned', data: undefined }
  }

  async delete(id: PrimaryKeyValueType): Promise<SingleResponse<Entity>> {
    const { data, error } = await supabase.from(this.tableName).update({ is_deleted: true }).eq(this.primaryKey, id).select(this.selectString).single()
    if (error) return { error: error.message, data: undefined }
    else return { data } as { data: Entity, error: undefined }
  }

  async deleteMany(toDelete: Entity[] | PrimaryKeyValueType[]): Promise<DataResponse<Entity>> {
    let ids: PrimaryKeyValueType[] = []
    if (typeof toDelete[0] !== 'object') ids = toDelete as PrimaryKeyValueType[]
    else ids = (toDelete as Entity[]).map((item) => item[this.primaryKey as keyof Entity]) as PrimaryKeyValueType[]
    const { data, error } = await supabase.from(this.tableName).update({ is_deleted: true }).in(this.primaryKey, ids).select(this.selectString)
    if (error) return { error: error.message, data: undefined }
    else return { data } as { data: Entity[], error: undefined }
  }

  async count(options?: QueryOptions): Promise<CountResponse> {
    const queryOptions = this.getQueryOptions(options)
    queryOptions.count = true
    queryOptions.select = options?.select || '*'
    queryOptions.filters = [...(options?.filters ?? []), { by: 'is_deleted', op: 'eq', value: true }]
    const query = buildQuery<Entity, TableName>(this.tableName, queryOptions)
    const { count, error } = await query

    // const { count, error } = await supabase.from(this.tableName).select('*', { count: 'exact', head: true }).not('deleted_at', 'is', null)
    if (error) return { error: error.message, count: undefined }
    else if (typeof count === 'number') return { count, error: undefined }
    else return { error: 'No count returned', count: undefined }
  }

  async restore(id: PrimaryKeyValueType): Promise<SingleResponse<Entity>> {
    const { data, error } = await supabase.from(this.tableName).update({ is_deleted: false }).eq(this.primaryKey, id).select(this.selectString).single()
    if (error) return { error: error.message, data: undefined }
    else return { data } as { data: Entity, error: undefined }
  }

  async restoreMany(toRestore: Entity[] | PrimaryKeyValueType[]): Promise<DataResponse<Entity>> {
    let ids: PrimaryKeyValueType[] = []
    if (typeof toRestore[0] !== 'object') ids = toRestore as PrimaryKeyValueType[]
    else ids = (toRestore as Entity[]).map((item) => item[this.primaryKey as keyof Entity]) as PrimaryKeyValueType[]
    const { data, error } = await supabase.from(this.tableName).update({ is_deleted: false }).in(this.primaryKey, ids).select(this.selectString)
    if (error) return { error: error.message, data: undefined }
    else return { data } as { data: Entity[], error: undefined }
  }
}

/**
 * Entity Feature (Full Text Search)
 */
class FullTextSearchAPI<Entity, TableName extends keyof Database['public']['Tables']> extends AbstractAPI implements IEntityAPIWithFTS<Entity> {
  async search(search: string, options?: QueryOptions): Promise<DataResponse<Entity>> {
    const queryOptions = this.getQueryOptions(options)
    const query = buildQuery<Entity, TableName>(this.tableName, queryOptions, search, options?.ftsColumn ?? this.ftsColumn)
    const { data, error } = await query
    if (error) return { error: error.message, data: undefined }
    else if (data) return { data } as { data: Entity[], error: undefined }
    else return { error: 'No data returned', data: undefined }
  }

  async count(search: string, options?: QueryOptions): Promise<CountResponse> {
    const queryOptions = this.getQueryOptions(options)
    queryOptions.count = true
    const query = buildQuery<Entity, TableName>(this.tableName, queryOptions, search)
    const { count, error } = await query
    if (error) return { error: error.message, count: undefined }
    else if (typeof count === 'number') return { count, error: undefined }
    else return { error: 'No count returned', count: undefined }
  }
}
type PublicType = Database['public']

function addFiltersToQuery(query: PostgrestFilterBuilder<GenericSchema, Record<string, unknown>, unknown>, filters: Filter[]) {
  filters.forEach(filter => {
    switch (filter.op) {
      case 'in':
        if (filter.value instanceof Array) query = query.in(filter.by, filter.value)
        else query = query.in(filter.by, [filter.value])
        break
      case 'startsWith':
        query = query.like(filter.by, `${filter.value}%`)
        break
      case 'endsWith':
        query = query.like(filter.by, `%${filter.value}`)
        break
      case 'contains':
        if (Array.isArray(filter.value)) query = query.contains(filter.by, filter.value)
        break
      case 'equals':
      case 'eq':
        query = query.eq(filter.by, filter.value as string)
        break
      case 'notEquals':
      case 'ne':
      case 'neq':
        query = query.neq(filter.by, filter.value)
        break
      case 'greaterThan':
      case 'gt':
        query = query.gt(filter.by, filter.value)
        break
      case 'lessThan':
      case 'lt':
        query = query.lt(filter.by, filter.value)
        break
      case 'greaterThanOrEqual':
      case 'gte':
        query = query.gte(filter.by, filter.value)
        break
      case 'lessThanOrEqual':
      case 'lte':
        query = query.lte(filter.by, filter.value)
        break
      case 'between':
        query = query.gte(filter.by, filter.value)
        query = query.lte(filter.by, filter.value2)
        break
      case 'notBetween':
        query = query.lt(filter.by, filter.value)
        query = query.gt(filter.by, filter.value2)
        break
      case 'isEmpty':
        query = query.is(filter.by, null)
        break
      case 'isNotEmpty':
        query = query.not(filter.by, 'is', null)
        break
      case 'isTrue':
        query = query.eq(filter.by, true)
        break
      case 'isFalse':
        query = query.eq(filter.by, false)
        break
      case 'like':
        if (typeof filter.value === 'string') query = query.like(filter.by, filter.value)
        break
      case 'ilike':
        if (typeof filter.value === 'string') query = query.ilike(filter.by, filter.value)
        break
      default:
        console.warn(`Unknown filter operator: '${filter.op}'`)
    }
  })
  return query
}

function buildQuery<Entity, TableName extends keyof Database['public']['Tables']>(tableName: string, options: QueryOptions, search?: string, ftsColumn?: string) {
  let query
  if (!options.select) options.select = '*'
  // count, select
  if (options.count) query = supabase.from(tableName).select(options.select, { count: 'exact', head: true })
  else query = supabase.from(tableName).select(options.select)
  // filters
  if (options.filters) addFiltersToQuery(query as PostgrestFilterBuilder<PublicType, Database['public']['Tables'][TableName], Entity>, options.filters)
  // FTS
  if (search) {
    const processedSearchString = search.split(' ').map(word => word + ':*').join(' & ')
    query.textSearch(options.ftsColumn ?? ftsColumn ?? fullTextSearchColumn, processedSearchString, { config: 'english' })
  }
  // sortBy, sortDescending
  if (options.sortBy) {
    // sort by field that is a foreign table key
    if (typeof options.sortBy !== 'string') {
      options.sortBy = options.sortBy as SortByForeign
      query.order(options.sortBy.by, { foreignTable: options.sortBy.table, ascending: !options.sortDescending })
    } else if (typeof options.sortBy === 'string') query.order(options.sortBy, { ascending: !options.sortDescending })
  }
  // start, end
  if (options.start !== undefined && options.end !== undefined) query.range(options.start, options.end)
  // limit
  if (options.limit) query.limit(options.limit)
  return query
}

/**
 * Entity
 */
export class EntityAPI<Entity, EntityInput, PrimaryKeyValueType extends string | number, TableName extends keyof Database['public']['Tables']> extends AbstractAPI implements IEntityAPI<Entity, EntityInput, PrimaryKeyValueType> {
  public softDelete: SoftDeletionAPI<Entity, PrimaryKeyValueType, TableName> | undefined
  public fts: FullTextSearchAPI<Entity, TableName> | undefined

  constructor(tableName: string, primaryKey: string, selectString = '*', features?: EntityAPIFeatures[], options?: APIOpts) {
    super(tableName, primaryKey, selectString, options)
    if (features) {
      if (features.includes(EntityAPIFeatures.softDelete)) this.softDelete = new SoftDeletionAPI<Entity, PrimaryKeyValueType, TableName>(tableName, primaryKey, selectString)
      if (features.includes(EntityAPIFeatures.fts)) this.fts = new FullTextSearchAPI<Entity, TableName>(tableName, primaryKey, selectString)
    }
  }

  async count(options?: QueryOptions): Promise<CountResponse> {
    const queryOptions = this.getQueryOptions(options)
    queryOptions.count = true
    const query = buildQuery<Entity, TableName>(this.tableName, queryOptions)
    const { count, error } = await query
    if (error) return { error: error.message, count: undefined }
    else if (typeof count === 'number') return { count, error: undefined }
    else return { error: 'No count returned', count: undefined }
  }

  async getAll(options?: QueryOptions): Promise<DataResponse<Entity>> {
    const queryOptions = this.getQueryOptions(options)
    if (this.softDelete) {
      if (!queryOptions.filters) queryOptions.filters = []
      // queryOptions.filters.push({ by: 'is_deleted', op: 'eq', value: false })
    }

    const query = buildQuery<Entity, TableName>(this.tableName, queryOptions)
    const { data, error } = await query
    if (error) return { error: error.message, data: undefined }
    else if (data) return { data: data as Entity[], error: undefined }
    else return { error: 'No data returned', data: undefined }
  }

  async get(id: PrimaryKeyValueType, selectString?: string): Promise<SingleResponse<Entity>> {
    const query = supabase.from(this.tableName).select(selectString || this.selectString).eq(this.primaryKey, id)
    if (!this.centralized) {
      const tenantId = useUserStore().activeTenantId
      query.eq('tenant_id', tenantId)
    }
    const { data, error } = await query.single()
    if (error) return { error: error.message, data: undefined }
    else return { data } as { data: Entity, error: undefined }
  }

  async add(itemToAdd: EntityInput, selectString?: string): Promise<SingleResponse<Entity>> {
    if (!this.centralized) {
      const tenantId = useUserStore().activeTenantId
      itemToAdd = { ...itemToAdd, tenant_id: tenantId }
    }
    const { data, error } = await supabase.from(this.tableName).insert(itemToAdd).select(selectString || this.selectString).single()
    if (error) return { error: error.message, data: undefined }
    else return { data } as { data: Entity, error: undefined }
  }

  async addMany(toAdd: EntityInput[]): Promise<DataResponse<Entity>> {
    if (!this.centralized) {
      const tenantId = useUserStore().activeTenantId
      toAdd = toAdd.map((item) => ({ ...item, tenant_id: tenantId }))
    }
    const { data, error } = await supabase.from(this.tableName).insert(toAdd).select(this.selectString)
    if (error) return { error: error.message, data: undefined }
    else return { data } as { data: Entity[], error: undefined }
  }

  async update(id: PrimaryKeyValueType, updates: Partial<EntityInput>): Promise<SingleResponse<Entity>> {
    if (!this.centralized) {
      const tenantId = useUserStore().activeTenantId
      updates = { ...updates, tenant_id: tenantId }
    }
    const { data, error } = await supabase.from(this.tableName).update(updates).eq(this.primaryKey, id).select(this.selectString).single()
    if (error) return { error: error.message, data: undefined }
    else return { data } as { data: Entity, error: undefined }
  }

  async updateAll(updates: Partial<EntityInput>): Promise<DataResponse<Entity>> {
    const { data, error } = await supabase.from(this.tableName).update(updates).not('id', 'is', null).select(this.selectString)
    if (error) return { error: error.message, data: undefined }
    else return { data } as { data: Entity[], error: undefined }
  }

  async updateMany(toUpdate: Entity[] | PrimaryKeyValueType[], updates: Partial<EntityInput>): Promise<DataResponse<Entity>> {
    let ids: PrimaryKeyValueType[] = []
    if (typeof toUpdate[0] !== 'object') ids = toUpdate as PrimaryKeyValueType[]
    else ids = (toUpdate as Entity[]).map((item) => item[this.primaryKey as keyof Entity]) as PrimaryKeyValueType[]
    const { data, error } = await supabase.from(this.tableName).update(updates).in(this.primaryKey, ids).select(this.selectString)
    if (error) return { error: error.message, data: undefined }
    else return { data } as { data: Entity[], error: undefined }
  }

  async updateByConditions(conditions: Condition<Entity>, updates: Partial<EntityInput>): Promise<DataResponse<Entity>> {
    const query = supabase.from(this.tableName).update(updates)
    for (const key in conditions) {
      const condition = conditions[key as keyof Entity]
      if (condition !== undefined) {
        switch (condition.operator) {
          case 'eq':
            query.eq(key, condition.value)
            break
          case 'neq':
            query.neq(key, condition.value)
            break
          case 'gt':
            query.gt(key, condition.value)
            break
          case 'gte':
            query.gte(key, condition.value)
            break
          case 'lt':
            query.lt(key, condition.value)
            break
          case 'lte':
            query.lte(key, condition.value)
            break
          case 'like':
            if (typeof condition.value === 'string') query.like(key, condition.value)
            break
          case 'ilike':
            if (typeof condition.value === 'string') query.ilike(key, condition.value)
            break
          case 'is':
            query.is(key, condition.value)
            break
        }
      }
    }

    const { data, error } = await query.select(this.selectString)
    if (error) return { error: error.message, data: undefined }
    else return { data } as { data: Entity[], error: undefined }
  }

  async hardDelete(id: PrimaryKeyValueType): Promise<SingleResponse<Entity>> {
    let query = supabase.from(this.tableName).delete().eq(this.primaryKey, id)
    if (!this.centralized) {
      const tenantId = useUserStore().activeTenantId
      query = query.eq('tenant_id', tenantId)
    }
    const { data, error } = await query.select(this.selectString).single()
    if (error) return { error: error.message, data: undefined }
    else return { data } as { data: Entity, error: undefined }
  }

  async hardDeleteMany(toDelete: Entity[] | PrimaryKeyValueType[]): Promise<DataResponse<Entity>> {
    let ids: PrimaryKeyValueType[] = []
    if (typeof toDelete[0] !== 'object') ids = toDelete as PrimaryKeyValueType[]
    else ids = (toDelete as Entity[]).map((item) => item[this.primaryKey as keyof Entity]) as PrimaryKeyValueType[]
    const { data, error } = await supabase.from(this.tableName).delete().in(this.primaryKey, ids).select(this.selectString)
    if (error) return { error: error.message, data: undefined }
    else return { data } as { data: Entity[], error: undefined }
  }

  async hardDeleteByConditions(conditions: Condition<Entity>): Promise<DataResponse<Entity>> {
    const query = supabase.from(this.tableName).delete()
    for (const key in conditions) {
      const condition = conditions[key as keyof Entity]
      if (condition !== undefined) {
        switch (condition.operator) {
          case 'eq':
            query.eq(key, condition.value)
            break
          case 'neq':
            query.neq(key, condition.value)
            break
          case 'gt':
            query.gt(key, condition.value)
            break
          case 'gte':
            query.gte(key, condition.value)
            break
          case 'lt':
            query.lt(key, condition.value)
            break
          case 'lte':
            query.lte(key, condition.value)
            break
          case 'like':
            if (typeof condition.value === 'string') query.like(key, condition.value)
            break
          case 'ilike':
            if (typeof condition.value === 'string') query.ilike(key, condition.value)
            break
          case 'is':
            query.is(key, condition.value)
            break
          case 'in':
            if (condition.value instanceof Array) query.in(key, condition.value)
            else query.in(key, [condition.value])
            break
        }
      }
    }

    const { data, error } = await query.select(this.selectString)
    if (error) return { error: error.message, data: undefined }
    else return { data } as { data: Entity[], error: undefined }
  }
}
