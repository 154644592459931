export enum TimesheetEntryBillingStatus {
  Active = 'active',
  Deferred = 'deferred',
  OnHold = 'on_hold',
  WrittenOff = 'written_off'
}

export enum ExpenseBillingStatus {
  Active = 'active',
  Deferred = 'deferred',
  OnHold = 'on_hold',
  WrittenOff = 'written_off'
}

export enum ProjectValidationStepType {
  Confirmation = 'confirmation',
  Controller = 'controller'
}

export enum EmploymentStatus {
  Active = 'active',
  Inactive = 'inactive',
  Terminated = 'terminated'
}

export enum TSOverheadType {
  General = 'general',
  PTO = 'pto',
  Holiday = 'holiday',
  FloatingHoliday = 'floating_holiday'
}

export enum PTOChangeType {
  Accrued = 'accrued',
  Used = 'used',
  Adjustment = 'adjustment',
  Rollover = 'rollover'
}

export enum PTORequestStatus {
  Pending = 'pending',
  Approved = 'approved',
  Denied = 'denied',
  Canceled = 'canceled'
}

export enum NotificationStatus {
  Unread = 'unread',
  Read = 'read'
}

export enum NotificationType {
  Request = 'request',
  Alert = 'alert'
}

export enum NotificationAction {
  Update = 'update',
  Create = 'create',
  Delete = 'delete',
  Approve = 'approve',
  Reject = 'reject',
  Comment = 'comment',
  Upload = 'upload',
  Join = 'join'
}

export enum OppStatus {
  Waiting = 'waiting',
  Won = 'won',
  Lost = 'lost',
  Hold = 'hold',
  Died = 'died'
}

export enum BidRequestStatus {
  Open = 'open',
  Closed = 'closed',
  Canceled = 'canceled'
}

export enum BidStatus {
  Pending = 'pending',
  Accepted = 'accepted',
  Rejected = 'rejected'
}

export enum TodoStatus {
  Pending = 'pending',
  InProgress = 'in_progress',
  Completed = 'completed'
}

export enum TaskStatus {
  NotStarted = 'not_started',
  InProgress = 'in_progress',
  OnHold = 'on_hold',
  ReadyForReview = 'ready_for_review',
  NeedsRevision = 'needs_revision',
  Completed = 'completed'
}

export enum InvoiceStatus {
  Unpaid = 'unpaid',
  Paid = 'paid',
  PartiallyPaid = 'partially_paid',
  Cancelled = 'canceled'
}

export enum ProjectStatus {
  Inactive = 'inactive',
  Validating = 'validating',
  OnHold = 'on_hold',
  Active = 'active',
  Ongoing = 'ongoing',
  Completed = 'completed',
  Canceled = 'canceled',
  Closed = 'closed'
}

export enum FeeType {
  LumpSum = 'lump_sum',
  TimeAndExpense = 'time_and_expense',
  NotToExceed = 'not_to_exceed'
}

export enum PaymentType {
  Cash = 'cash',
  Check = 'check',
  CreditDebit = 'credit_debit',
  BankTransfer = 'bank_transfer'
}

export const todoStatuses = [{
  name: 'Pending',
  value: 'pending',
  color: '#d6d6d6',
  icon: 'o_hourglass_empty'
}, {
  name: 'In Progress',
  value: 'in_progress',
  color: '#aedff7',
  icon: 'o_autorenew'
}, {
  name: 'Completed',
  value: 'completed',
  color: '#c8e6c9',
  icon: 'o_check_circle'
}]

export const taskStatuses = [{
  name: 'Not Started',
  value: 'not_started',
  color: '#d6d6d6',
  icon: 'o_schedule'
}, {
  name: 'In Progress',
  value: 'in_progress',
  color: '#aedff7',
  icon: 'o_autorenew'
}, {
  name: 'On Hold',
  value: 'on_hold',
  color: '#ffedb5',
  icon: 'o_pause_circle_filled'
}, {
  name: 'Ready for Review',
  value: 'ready_for_review',
  color: '#c8e6c9',
  icon: 'o_search'

}, {
  name: 'Needs Revision',
  value: 'needs_revision',
  color: '#ffcdd2',
  icon: 'o_edit'
}, {
  name: 'Completed',
  value: 'completed',
  color: '#d1c4e9',
  icon: 'o_check_circle'
}]
